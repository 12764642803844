import {Component, OnInit} from '@angular/core';
import {Global} from '../global';
import {ValidateSpanishID} from './validaDNI';
import {Location} from '@angular/common';

declare var $;

@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styleUrls: ['./apertura.component.scss']
})
export class AperturaComponent implements OnInit {
  paso = 1;
  apertura = false;
  formulario;
  poblaciones = [];
  cargando = false;
  subiendo_albaran = false;
  n_files = {};
  documentos = [];
  subiendo = {};
  files = [];
  allDocumentos = [];
  tipologias = [];
  causas = [];
  grm_id;
  pol_id;
  pol_oficode;
  confirmar;
  numpoliza;
  //cias = [];
  cliente;
  codigoSeguridad;
  newContrario;
  nuevoContrario;
  showCaptcha;
  errorShowcaptcha;
  entidad;
  logo;
  backgroundColor;
  backgroundColorHover;
  showInit = false;
  currentYear;
  peritaje;
  fecha_peritaje = {
    value: '',
    error: null
  };
  cp_peritaje = {
    value: '',
    error: null,
    isDisabled: false
  };
  poblacion_taller_peritaje;
  show_info_taller = false;
  taller_peritaje;
  peritajes_taller;

  hide_selectTaller = true;
  hour_limit_next_day = 12;
  show_modal_info = false;
  show_modal_otro_taller = false;

  show_btn_search_taller = false;

  show_enviar_peritaje_btn = false;

  isDataAvailable: boolean = false;
  aperturar_nuevo_siniestro = false;

  peritajesolicitado = false;
  peritajeSolicitadoIndefinido = false;
  peritajeSolicitadoTaller=false;

  taller_peritaje_poblacion = '';
  taller_peritaje_direccion = '';
  taller_peritaje_telefono = '';
  taller_peritaje_mapa = null;
  hasTalleres = true;
  selectTaller = 0;
  talleres_mensaje = '';
  festivos = [];


  dateIncorrect = false;


  errorContrario = false;
  errorDocumentacion = false;
  sin_id;
  sin_alias;
  sin_fechaSiniestro;
  matricula;


  constructor(public global: Global, private location: Location) {
    this.currentYear = new Date().getUTCFullYear();
  }

  ngOnInit() {

    this.doAsyncTask();
    this.getDocuments();
    this.init_formulario();
    this.getFestivos();
    // this.formulario.sin_id.value=210613;
    this.paso = 1;
    this.peritajesolicitado = false;

    //  this.pol_id=10593004;

    //
    // this.sin_alias =2022000817;
    //
    // console.log(this.sin_alias);
    // this.sin_id=210613;
    // this.sin_alias=1994772268;
    //  this.paso=6;


  }

  async doAsyncTask() {
    var promise = new Promise((resolve, reject) => {
      setTimeout(() => {

        this.lookAndFeel();
        this.showInit = true;
        resolve();
      }, 1000);
    });
  }

  resolved(captchaResponse: string) {
    sessionStorage.setItem('token-autos-siniestros', captchaResponse);
    this.errorShowcaptcha = null;
  }


  async checkIdentificadorSiniestro() {

    this.cargando=true;
    this.sin_id = this.formulario.identificador_siniestro.value;
    let r = true;
    if (this.formulario.identificador_siniestro.value == '') {
      this.formulario.identificador_siniestro.error = 'Debe introducir un número de siniestro';
      this.cargando=false;
      r = false;
    }

    this.initDatePicker();

    let getSinId = await this.global.apiCall('siniestros/getSinId', 'POST', false, {sin_alias: this.formulario.identificador_siniestro.value});
    console.log(getSinId);
    if (getSinId['status']) {
      this.sin_id = getSinId['sin_id'];
      this.sin_alias = this.formulario.identificador_siniestro.value;
      this.pol_id = getSinId['pol_id'];
      this.sin_fechaSiniestro = getSinId['sin_fechaSiniestro'];
      this.matricula = getSinId['riesgo'];
      this.cargando = false;
      this.paso = 2;
    } else {
      this.formulario.identificador_siniestro.error = getSinId['message'];
      this.cargando = false;
      r = false;
    }


    return r;
  }

  async getDocuments() {
    let result = await this.global.apiCall('siniestrosAutos/getDocuments', 'POST', false, {});
    if (result.status == false) {
      if (result.message) {
        alert(result.message);
      }
      return;
    }

    this.allDocumentos = result.data;

  }

  async getFestivos() {
    let responseFestivos = await this.global.apiCall('siniestrosAutos/festivos', 'POST', false, {});

    for (var i in responseFestivos) {
      this.festivos.push(responseFestivos[i]['fecha']);
    }
  }

  async getTipologiaSiniestros() {

    this.cargando = true;
    let result = await this.global.apiCall('siniestrosAutos/tipologiaSiniestros', 'POST', false, {grm_id: this.grm_id});
    this.cargando = false;
    if (result.status == false) {
      if (result.message) {
        alert(result.message);
      }
      return;
    }

    this.tipologias = result.data;
    if (this.causas.length == 1) {
      this.formulario.tipologiaSiniestro.value = this.tipologias[0].tps_id;
    } else {
      this.tipologias.push({
        tps_id: -1,
        tps_codigo: -1,
        tps_descripcion: 'No estoy seguro'
      });
    }
  }

  async getCausas() {

    this.causas = [];
    this.formulario.causaSiniestro.value = '';

    if (this.formulario.tipologiaSiniestro.value == null) {
      return;
    }
    let tipologia = this.tipologias.find(t => t.tps_id == this.formulario.tipologiaSiniestro.value);
    this.cargando = true;
    let result = await this.global.apiCall('siniestrosAutos/causaSiniestros', 'POST', false, {tps_codigo: tipologia.tps_codigo});
    this.cargando = false;
    if (result.status == false) {
      if (result.message) {
        alert(result.message);
      } else {
        this.causas.push({
          csn_descripcion: 'No estoy seguro',
          csn_id: -1
        });
        this.formulario.causaSiniestro.value = this.causas[0].csn_id;
      }
      return;
    }

    this.causas = result.data;
    if (this.causas.length == 1) {
      this.formulario.causaSiniestro.value = this.causas[0].csn_id;
    } else {
      this.causas.push({
        csn_descripcion: 'No estoy seguro',
        csn_id: -1
      });
    }
  }

  async lookAndFeel() {

    this.entidad = this.location.path();

    let logodefault = './assets/img/logo_ersm_600.png';

    this.logo = '';
    this.cargando = true;
    if (this.entidad) {
      this.entidad = this.entidad.replace('/', '');
      let look_and_feel = await this.global.apiCall('lookAndFeel/getStyles', 'POST', false, {entidad: this.entidad});
      console.log(look_and_feel);
      if (look_and_feel.status == true) {
        var logoimg = look_and_feel.data.logo;
        if (logoimg === undefined) {
          logoimg = look_and_feel.data.Logo;
        }
        this.logo = 'https://media.ersm.net/siniestros/logos/' + logoimg;
        var color = look_and_feel.data.color;
        if (color === undefined) {
          color = look_and_feel.data.Color;
        }
        this.backgroundColor = color;
      } else {
        this.logo = logodefault;
      }
    } else {
      this.logo = logodefault;
    }
    this.cargando = false;
  }


  init_formulario() {

    this.formulario = {

      // poliza: {
      //   value: '',
      //   error: null,
      //   paso: 1
      // },
      // matricula: {
      //   value: '',
      //   error: null,
      //   paso: 1
      // },
      // nifTomador: {
      //   value: '',
      //   error: null,
      //   paso: 2
      // },
      // nifColaborador: {
      //   value: '',
      //   error: null,
      //   paso: 2
      // },
      // movil: {
      //   value: '',
      //   error: null,
      //   paso: 2
      // },
      // email: {
      //   value: '',
      //   error: null,
      //   paso: 2
      // },
      // tipologiaSiniestro: {
      //   value: null,
      //   error: null,
      //   paso: 3
      // },
      // causaSiniestro: {
      //   value: null,
      //   error: null,
      //   paso: 3
      // },
      // fecha: {
      //   value: '',
      //   error: null,
      //   paso: 3
      // },
      // dirPerjudicado: {
      //   value: '',
      //   error: null,
      //   paso: 3
      // },
      // cp: {
      //   value: '',
      //   error: null,
      //   paso: 3
      // },
      // poblacion: {
      //   value: '',
      //   error: null,
      //   paso: 3
      // },
      // circunstancias: {
      //   value: '',
      //   error: null,
      //   paso: 3
      // },
      // danos: {
      //   value: '',
      //   error: null,
      //   paso: 3
      // },
      // contrario: {
      //   value: '0',
      //   error: null,
      //   paso: 4,
      // },
      // contrarios: {
      //   value: [],
      //   paso: 4
      // },
      identificador_siniestro: {
        value: '',
        error: null
        // paso: 5
      }

    };

    // this.newContrario = false;
    // this.cliente = '0';
    // this.paso = 1;
    // this.poblaciones = [];
    // this.files = [];
    // this.documentos = [];
    // this.confirmar = false;
    // this.numpoliza = '';

    // var disableddates = ['20-12-2021', '12-12-2021', '12-25-2014', '12-20-2014'];

    this.iniTable(null);
    setTimeout(() => {
      $('#fecha').datepicker({
        language: 'es',
        dateFormat: 'dd/mm/yy',
        maxDate: 0,
        firstDay: 1,
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],

      }).change(() => {
        this.formulario.fecha.error = null;
      });

      $('#select2Documentos').val(this.documentos);
      $('#select2Documentos').select2({
        dropdownParent: $('#panel'),
        placeholder: 'Selecciona los documentos a añadir',
      }).on('select2:select', (e) => {
        let doc = this.allDocumentos.find(t => t.doc_id == e.params.data.id);
        this.formulario[doc.doc_descripcion] = {
          value: [],
          error: null,
          id: doc.doc_id,
          paso: 5
        };
        this.subiendo[doc.doc_id] = false;
        this.n_files[doc.doc_id] = 1;
        this.documentos.push(doc);
        if (this.documentos.length == 1) {
          $('#docsTable').DataTable().destroy();
          this.iniTable(0);
        }
      }).on('select2:unselect', (e) => {
        let doc = this.documentos.find(t => t.doc_id == e.params.data.id);
        this.documentos.splice(this.documentos.indexOf(doc), 1);
        if (this.documentos.length == 0) {
          $('#docsTable').DataTable().destroy();
          this.iniTable(0);
        }
      });
    }, 100);

    if (sessionStorage.getItem('token-autos-siniestros')) {
      this.showCaptcha = false;
      if (localStorage.getItem('poliza')) {
        this.formulario.poliza.value = localStorage.getItem('poliza');
      }
      if (localStorage.getItem('matricula')) {
        this.formulario.matricula.value = localStorage.getItem('matricula');
      }
    } else {
      this.showCaptcha = true;
    }

  }

  iniTable(id) {
    setTimeout(() => {
      if (id == 0 || id == null) {
        $('#docsTable').DataTable({
          lengthMenu: [150],
          language: {
            url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          },
        });
      }
      if (id == 1 || id == null) {
        $('#contrariosTable').DataTable({
          lengthMenu: [150],
          language: {
            url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          },
        });
      }
    }, 100);
  }

  existsFiles(name) {
    if (this.files.filter(f => f.document == name).length == 0) {
      return false;
    }
    return true;
  }

  async subir_documentos(fileInput: any, documentName, docId) {

    this.subiendo[docId] = true;

    let files = <Array<File>> fileInput.target.files;

    for (let file of files) {

      this.files.push({
        file: file,
        name: file.name,
        document: documentName,
        nombre: documentName + '_' + this.n_files[docId].toString(),
        id: docId
      });

    }

    this.n_files[docId]++;

    $('input[type="file"]').val('');

    this.subiendo[docId] = false;

  }

  async comprobar_campos_paso1(evt = null) {

    if (evt) {
      return false;
    }

    let r = true;

    if (this.paso == 1) {

      this.formulario.poliza.error = null;
      this.formulario.matricula.error = null;

      if (this.formulario.poliza.value == '' && this.formulario.matricula.value == '') {
        this.formulario.poliza.error = 'Debe indicar al menos o la póliza o la matrícula';
        this.formulario.matricula.error = 'Debe indicar al menos o la póliza o la matrícula';
        r = false;
      }
    }

    return r;

  }

  async comprobar_campos_paso2(evt = null) {

    if (evt) {
      return false;
    }

    let r = true;

    if (this.paso == 2) {

      for (let campo in this.formulario) {

        if (this.formulario[campo].paso == 2) {

          this.formulario[campo].error = null;

          if (this.cliente == 0) {
            if (campo == 'nifColaborador') {
              continue;
            }
            if (this.formulario[campo].value == '') {
              this.formulario[campo].error = 'Completa el campo';
              r = false;
            }

            let v = ValidateSpanishID(this.formulario.nifTomador.value.replace('-', ''));
            if (v.type != 'cif' && v.type != 'dni') {

              this.formulario.nifTomador.error = 'Introduzca un NIF/CIF';
              r = false;

            } else if (!v.valid) {

              this.formulario.nifTomador.error = 'Introduzca un NIF/CIF válido';
              r = false;

            }

            if (!(/^[\d]{3}[-]*([\d]{2}[-]*){2}[\d]{2}$/).test(this.formulario.movil.value)) {

              this.formulario.movil.error = 'Introduzca un número de teléfono válido';
              r = false;

            }

            if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.formulario.email.value))) {

              this.formulario.email.error = 'Introduzca un email válido';
              r = false;

            }

          } else {
            if (campo != 'nifColaborador' && campo != 'nifTomador') {
              continue;
            }
            if (this.formulario[campo].value == '') {
              this.formulario[campo].error = 'Completa el campo';
              r = false;
            }
            if (r != false) {
              let v = ValidateSpanishID(this.formulario[campo].value.replace('-', ''));
              if (v.type != 'cif' && v.type != 'dni') {
                this.formulario[campo].error = 'Introduzca un NIF/CIF';
                r = false;
              } else if (!v.valid) {
                this.formulario[campo].error = 'Introduzca un NIF/CIF válido';
                r = false;
              }
            }
          }
        }
      }
    }

    if (r != false) {
      if (this.cliente == 0) {
        this.formulario.nifColaborador.value = '';
      }
      this.cargando = true;
      let result = await this.global.apiCall('siniestrosAutos/checkParams', 'POST', false, {
        poliza: this.formulario.poliza.value,
        matricula: this.formulario.matricula.value,
        nifTomador: this.formulario.nifTomador.value,
        nifColaborador: this.formulario.nifColaborador.value
      });
      this.cargando = false;
      if (result.status == false) {
        if (this.formulario.nifTomador.value != '') {
          this.formulario.nifTomador.error = result.message;
        }
        r = false;
        return r;
      } else {

        this.pol_id = result.data.POL_Id;
        this.pol_oficode = result.data.pol_oficode;
        this.grm_id = result.data.grm_id;
      }
    }

    return r;

  }

  async comprobar_campos_paso3(evt = null) {

    if (evt) {
      return false;
    }

    let r = true;

    if (this.paso == 3) {

      this.formulario.fecha.value = this.convertDataYmd($('#fecha').val());

      for (let campo in this.formulario) {

        if (campo == 'poblacion') {
          continue;
        }

        if (this.formulario[campo].paso == 3) {

          this.formulario[campo].error = null;

          if (this.formulario[campo].value == '') {
            this.formulario[campo].error = 'Completa el campo';
            r = false;
          }


        }

      }

      let fecha = new Date(this.formulario.fecha.value);
      let hoy = new Date();
      let dias = Math.floor((fecha.getTime() - hoy.getTime()) / (1000 * 60 * 60 * 24));

      if (dias >= 0) {
        this.formulario.fecha.error = 'La fecha no puede ser posterior a hoy';
        r = false;
      }

      let c = await this.checkCP(this.formulario.cp.value);

      if (!c) {
        r = false;
      }

      if (this.formulario.poblacion.value == '' && this.poblaciones.length != 0) {
        this.formulario.poblacion.value = this.poblaciones[0];
      }
    }

    return r;

  }

  async comprobar_campos_paso4(evt = null) {

    if (evt) {
      return false;
    }

    let r = true;
    this.errorContrario = false;

    if (this.paso == 4 && this.formulario.contrario.value == 1 && this.formulario.contrarios.value == 0) {
      this.errorContrario = true;
    }

    if (this.paso == 4 && this.formulario.contrario.value == 1) {


      for (let campo in this.formulario) {

        if (this.formulario[campo].paso == 4) {

          this.formulario[campo].error = null;

          if (this.formulario[campo].value == '') {
            this.formulario[campo].error = 'Completa el campo';
            r = false;
          }


        }

      }

    }


    return r;

  }

  async confirmaDatos(value) {
    if (value == 1) {
      this.formulario.poliza.value = this.numpoliza;
      this.confirmar = false;
      this.paso2();
    } else {
      this.numpoliza = '';
      this.confirmar = false;
      return;
    }
  }

  async paso2() {

    if (!sessionStorage.getItem('token-autos-siniestros')) {
      this.errorShowcaptcha = 'Completa el campo';
      return;
    }

    let c = await this.comprobar_campos_paso1();

    if (!c) {
      return;
    }

    if (this.formulario.poliza.value != '') {
      localStorage.setItem('poliza', this.formulario.poliza.value);
    }
    if (this.formulario.matricula.value != '') {
      localStorage.setItem('matricula', this.formulario.matricula.value);
    }

    this.paso = 2;


  }

  async paso3() {

    let c = await this.comprobar_campos_paso2();

    if (!c) {
      return;
    }

    await this.getTipologiaSiniestros();

    this.paso = 3;

  }

  async paso4() {

    let c = await this.comprobar_campos_paso3();

    if (!c) {
      return;
    }

    this.paso = 4;
  }

  async paso5() {

    let c = await this.comprobar_campos_paso4();

    if (!c) {
      return;
    }

    this.paso = 5;
  }


  clickInput(input) {
    document.getElementById('input_' + input).click();
  }

  abrir_documento(file) {

    window.open(file.url);

  }

  aperturar() {
    if (localStorage.getItem('matricula')) {
      localStorage.removeItem('matricula');
    }
    if (localStorage.getItem('poliza')) {
      localStorage.removeItem('poliza');
    }
    // this.init_formulario();
    this.apertura = true;
  }

  cancelarApertura() {
    this.apertura = false;
  }

  async checkCP_Peritaje(cp) {


        var fecha = $('#fecha_peritaje').val();
        var regex = /^\d{2}\/\d{2}\/\d{4}$/;

        if (!regex.test(fecha)  ) {
            alert("La fecha no tiene el formato correcto.");
            return false;
        }
        if(this.isWeekend(fecha)) {
         this.dateIncorrect = true;
                       alert("La fecha introducida no es válida.");
                       return false;
                   }


    this.show_modal_info = false;
    this.show_modal_otro_taller = false;

    this.show_info_taller = false;
    this.show_enviar_peritaje_btn = false;
    this.poblaciones = [];
    this.cp_peritaje.error = null;


    if (this.cp_peritaje.value == '') {

      this.cp_peritaje.error = 'Completa el campo';
      return false;

    } else if (isNaN(parseFloat(this.cp_peritaje.value))) {

      this.cp_peritaje.error = 'Introduzca un código postal válido';
      return false;

    } else if (this.cp_peritaje.value.length != 5) {

      this.cp_peritaje.error = 'Introduzca un código postal válido';
      return false;

    }

    this.cargando = true;
    let result = await this.global.apiCall('siniestrosAutos/checkCP', 'POST', false, {cp: cp});
    this.cargando = false;

    if (!result.status) {
      this.cp_peritaje.error = result.message;
      this.hasTalleres = false;
      this.selectTaller = null;
      this.show_info_taller = false;
      this.hide_selectTaller = true;
      return false;
    }

    this.selectTaller = 0;

    var rbutton = document.getElementById('radiobuttonST') as HTMLInputElement;
    rbutton.checked = true;


    this.hasTalleres = true;


    this.peritajes_taller = await this.listadoTalleres(cp, this.pol_id);

    if (this.peritajes_taller.length == 0) {
      this.cp_peritaje.isDisabled = true;
      this.show_modal_info = true;
      this.hide_selectTaller = true;
      this.selectTaller = null;
      return false;
    }

    this.hide_selectTaller = false;
    this.show_btn_search_taller = true;
    this.taller_peritaje = this.peritajes_taller[0];
    this.show_info_taller = true;
    this.show_enviar_peritaje_btn = true;

    let poblacionselected = await this.global.apiCall('siniestrosAutos/checkCP', 'POST', false, {cp: this.taller_peritaje.prs_cp});

    this.taller_peritaje_direccion = this.taller_peritaje.prs_direccion;
    this.taller_peritaje_poblacion = this.taller_peritaje.prs_localidad;
    this.taller_peritaje_telefono = this.taller_peritaje.prs_telfpal;
    this.taller_peritaje_mapa = this.taller_peritaje.mapa;


    return true;
  }

  async checkCP(cp) {

    this.poblaciones = [];

    this.formulario.cp.error = null;

    if (this.formulario.cp.value == '') {

      this.formulario.cp.error = 'Completa el campo';
      return false;

    } else if (isNaN(parseFloat(this.formulario.cp.value))) {

      this.formulario.cp.error = 'Introduzca un código postal válido';
      return false;

    } else if (this.formulario.cp.value.length != 5) {

      this.formulario.cp.error = 'Introduzca un código postal válido';
      return false;

    }

    this.cargando = true;
    let result = await this.global.apiCall('siniestrosAutos/checkCP', 'POST', false, {cp: cp});
    this.cargando = false;

    if (!result.status) {

      this.formulario.cp.error = result.message;
      return false;

    }

    this.poblaciones = result.data;

    return true;

  }

  uploadFile(file, name) {

    let size = file.size / 1024 / 1014;

    if (size >= 20) {
      return {
        status: false,
        message: 'No se pueden subir archivos con un tamaño superior a 20Mb'
      };
    }

    var formData = new FormData();

    formData.append('select_file', file, file.name);
    formData.append('file_name', name);
    formData.append('sin_id', this.formulario.sin_id.value);

    return $.ajax({
      url: this.global.config.api + 'siniestrosAutos/uploadFile',
      method: 'POST',
      data: formData,
      dataType: 'JSON',
      contentType: false,
      cache: false,
      processData: false
    });

  }

  async delete_file(key, i) {

    this.files.splice(i, 1);

  }

  async uploadDocumentos() {

    for (let file of this.files) {
      this.cargando = true;

      let result = await this.uploadFile(file.file, file.nombre);

      this.cargando = false;

      if (result.message) {
        alert('Se ha producido un error en la subida de documentos');
        return;
      }

      let url = result.url;
      let new_name = result.new_name;

      let type = file.file.type.split('/')[0];

      this.formulario[file.document].value.push({
        url: url,
        type: type,
        name: file.name,
        new_name: new_name
      });
    }
  }

  newCont() {
    this.nuevoContrario = {
      nombre: {
        value: '',
      },
      apellido1: {
        value: '',
      },
      apellido2: {
        value: '',
      },
      matricula: {
        value: '',
        error: ''
      },
      cia: {
        value: '',
        error: ''
      },
      poliza: {
        value: '',
        error: ''
      }
    };
    this.newContrario = true;
  }

  deleteCont(i) {
    this.formulario.contrarios.value.splice(i, 1);
    if (this.formulario.contrarios.value.length == 0) {
      $('#contrariosTable').DataTable().destroy();
      this.iniTable(1);
    }
  }

  comprobarCamposNuevoContrario() {
    let r = true;
    if (this.nuevoContrario.matricula.value == '') {
      this.nuevoContrario.matricula.error = 'Completa el campo';
      r = false;
    }
    if (this.nuevoContrario.cia.value == '') {
      this.nuevoContrario.cia.error = 'Completa el campo';
      r = false;
    }
    if (this.nuevoContrario.poliza.value == '') {
      this.nuevoContrario.poliza.error = 'Completa el campo';
      r = false;
    }
    return r;
  }


  saveContrario() {
    if (!this.comprobarCamposNuevoContrario()) {
      return;
    }
    if (this.nuevoContrario.nombre.value == '') {
      this.nuevoContrario.nombre.value = 'Contrario' + (this.formulario.contrarios.value.length + 1);
    }
    this.formulario.contrarios.value.push(this.nuevoContrario);
    this.nuevoContrario = {};
    this.newContrario = false;
    if (this.formulario.contrarios.value.length == 1) {
      $('#contrariosTable').DataTable().destroy();
      this.iniTable(1);
    }
  }

  async grabar_siniestro() {

    this.cargando = true;

    let data = {};
    for (let campo in this.formulario) {
      if (this.formulario[campo].paso != 5) {
        if (campo == 'contrarios') {
          data[campo] = [];
          for (let contrario of this.formulario[campo].value) {
            let auxContrario = {};
            for (let campo2 in contrario) {
              auxContrario[campo2] = contrario[campo2].value;
            }
            data[campo].push(auxContrario);
          }
        } else {
          data[campo] = this.formulario[campo].value;
        }
      }
    }

    data['pol_id'] = this.pol_id;
    data['pol_oficode'] = this.pol_oficode;

    let result = await this.global.apiCall('siniestrosAutos/grabar_siniestro', 'POST', false, data);
    this.cargando = false;

    if (!result.status) {
      alert(result.message);
    } else {

      this.formulario.sin_id.value = result.data;

      this.sin_id = result.data;


      if (this.documentos.length > 0 && this.files.length == 0) {
        this.errorDocumentacion = true;
        return;
      }
      let tempsin_alias = await this.global.apiCall('siniestros/getSinAlias', 'POST', false, {sin_id: this.sin_id});

      this.sin_alias = tempsin_alias['sin_alias'];
      console.log(this.sin_alias);


      await this.uploadDocumentos();


      this.cargando = true;

      data = {};

      for (let campo in this.formulario) {

        if (this.formulario[campo].paso != 5) {
          continue;
        }

        data[campo] = this.formulario[campo].value;

      }

      result = await this.global.apiCall('siniestrosAutos/cargarFicheros', 'POST', false, {data: data, docs: this.documentos});

      this.cargando = false;

      if (!result.status) {

        alert(result.message);
        return;

      }


      this.paso = 6;


    }

  }


  setStylesButton() {

    if (this.entidad) {
      let styles = {
        'background': this.backgroundColor,
        'border-color': this.backgroundColor,
      };
      return styles;
    } else {
      return false;
    }
  }

  setStylesText() {
    if (this.entidad) {
      let styles = {
        'color': this.backgroundColor,

      };
      return styles;
    } else {
      return false;
    }
  }

  setStylesTopBar() {
    if (this.entidad) {
      let styles = {
        'border-top-color': this.backgroundColor
      };
      return styles;
    }
  }

  setStyleTopPanel() {
    if (this.entidad) {
      let styles = {
        'background': this.backgroundColor
      };
      return styles;
    }
  }

  peritajeFecha() {

    const today = new Date();

    const fechaperitaje = new Date();
    var dateadd = 0;
    if (fechaperitaje.getHours() >= this.hour_limit_next_day) {
      dateadd = 2;
      fechaperitaje.setDate(today.getDate() + 2);
    } else {
      dateadd = 1;
      fechaperitaje.setDate(today.getDate() + 1);
    }
    let day = String(fechaperitaje.getDate()).padStart(2, '0');
    let month = String(fechaperitaje.getMonth() + 1).padStart(2, '0');
    let year = fechaperitaje.getFullYear();

    var datareturn = {
      date: day + '/' + month + '/' + year,
      blockday: dateadd,
    };

    return datareturn;
  }


  initDatePicker() {
    this.resetShowModal();
    this.cp_peritaje.isDisabled = false;
    this.aperturar_nuevo_siniestro = false;
    this.fecha_peritaje.value = this.peritajeFecha().date;
    var blockday = this.peritajeFecha().blockday;
    var diasfestivos = this.festivos;

    $('#fecha_peritaje').datepicker({
      regional: 'es',
      dateFormat: 'dd/mm/yy',
      minDate: blockday,
      beforeShowDay: function(date) {

        var dayweek = date.getDay();
        var day = date.getDate().toString().padStart(2, '0');
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var fulldate = day + '/' + month + '/' + year;

        return [
          diasfestivos.indexOf(fulldate) == -1 && dayweek != 0 && dayweek != 6
        ];


      },
      firstDay: 1,
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],

      onSelect: (dateText, el) => {

        var date = dateText;

        if (diasfestivos.includes(date) || this.isWeekend(date)) {
          this.dateIncorrect = true;
          alert('El día seleccionado no está habilitado. Por favor, elija otro día');
          return false;

        }
      },
       onClose:(dateText, el) => {

                       var date = dateText;
                      if (diasfestivos.includes(date) || this.isWeekend(date)) {
                         this.dateIncorrect = true;
                         alert('El día seleccionado no está habilitado. Por favor, elija otro día');
                         return false;

                       }
                       }

    });
  }



  async listadoTalleres(codigopostal, polId) {
    this.cargando = true;
    var result = await this.global.apiCall('siniestrosAutos/listadoTalleres', 'POST', false, {cp: codigopostal, pol_id: polId});
    this.talleres_mensaje = result.message;
    this.cargando = false;

    return result.data;
  }

isWeekend(dateString) {
  // Crea un objeto Date a partir de la cadena de fecha proporcionada
   const [day, month, year] = dateString.split('/');

   // Crea una nueva cadena en formato 'Y-m-d'
   const formattedDateString = `${year}-${month}-${day}`;

   // Crea un objeto Date a partir de la cadena de fecha formateada
   const date = new Date(formattedDateString);

   // Verifica si la fecha es válida

   // Obtiene el día de la semana (0 = Domingo, 6 = Sábado)
   const dayOfWeek = date.getDay();

   // Verifica si el día es Sábado (6) o Domingo (0)
   return dayOfWeek === 0 || dayOfWeek === 6;
}

  showTallerInfo() {

    this.taller_peritaje_direccion = this.taller_peritaje.prs_direccion;
    this.taller_peritaje_poblacion = this.taller_peritaje.prs_localidad;
    this.taller_peritaje_telefono = this.taller_peritaje.prs_telfpal;
    this.taller_peritaje_mapa = this.taller_peritaje.mapa;

    this.show_modal_info = false;
    this.show_info_taller = true;
    this.show_enviar_peritaje_btn = true;


  }

  selectTallerOption() {
    this.resetShowModal();

  }

  resetShowModal() {
    this.show_modal_otro_taller = false;
    this.show_modal_info = false;
  }

  showModalNoTaller() {
    this.resetShowModal();
    this.cp_peritaje.isDisabled = true;
    this.show_modal_otro_taller = true;

  }

  showModalOtroTaller() {
    this.resetShowModal();
    this.show_modal_otro_taller = true;
  }

  noPeritaje() {
    this.resetShowModal();
    this.aperturar_nuevo_siniestro = true;
  }

  changeCP() {
    this.cp_peritaje.isDisabled = false;
    this.show_modal_info = false;
  }


  async altaPeritaje() {
    $('#btn-alta-peritaje').attr('disabled','disabled');
    this.cargando = true;
    var data = {};

    data['sin_id'] = this.sin_id;
    data['prs_id'] = this.taller_peritaje.prs_id;
    data['fecha'] = this.convertDataYmd($('#fecha_peritaje').val());

    var result = await this.global.apiCall('siniestrosAutos/altaPeritaje', 'POST', false, {data: data});
    this.cargando = false;
    this.peritajesolicitado = true;
    this.peritajeSolicitadoTaller = true;

    this.paso = 7;
    this.apertura = false;

  }

  async altaPeritajeIndefinido() {
    $('.btn-alta-peritaje-indefinido').attr('disabled','disabled');
    this.cargando = true;
    var data = {};
    data['sin_id'] = this.sin_id;
    // data['sin_id']=   115892118;
    data['fecha'] = this.convertDataYmd($('#fecha_peritaje').val());
    var result = await this.global.apiCall('siniestrosAutos/altaPeritajeIndefinido', 'POST', false, {data: data});
    this.cargando = false;
    this.peritajeSolicitadoIndefinido = true;
    this.peritajesolicitado = true;
    this.paso = 7;
    this.apertura = false;

  }

  convertDataYmd(date) {
    var newdate = date.split('/').reverse().join('/');
    return newdate;
  }


  inicio() {
    location.reload();
  }

}
