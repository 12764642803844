import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RecaptchaModule} from 'ng-recaptcha';

// import { AppRoutingModule } from './app-routing.module';
import {AppComponent} from './app.component';
import {Routes, RouterModule} from '@angular/router';

import {Global} from './global';
import {AperturaComponent} from './apertura/apertura.component';

const routes: Routes = [
  {
    path: '',
    component: AperturaComponent
  }, {
    path: '**',
    component: AperturaComponent
  },
];

@NgModule({
  declarations: [
    AppComponent,
    AperturaComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {useHash: true}),
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule
  ],
  providers: [Global],
  bootstrap: [AppComponent]
})
export class AppModule {
}

